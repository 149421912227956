//import Backbone from 'backbone';
/* eslint no-console: 0*/
/* eslint no-unused-vars: 0*/
/* global localize */
/* global tickets */

/* Note: These are getting passed as localized
 * var accordions = {
 * "tickets_show_more":"More tickets","tickets_show_less":"Less tickets","tickets_images_to_show":"0","tickets_images_threshold":"0",
 * "actors_show_more":"More actors","actors_show_less":"Less actors","actors_images_to_show":"0","actors_images_threshold":"0",
 * "gallery_show_more":"More images","gallery_show_less":"Less images","gallery_images_to_show":"0","gallery_images_threshold":"0"};
 */

export default {
    init() {

        // Hide  the Read More button on front page at start
        if ($('.frontPage--readmore').length) {
            $('.frontPage--readmore').hide(0);
        }

        // Function to fade in the read more button
        function frontpageBtnAnimation(target = null) {
            if ($('.frontPage--buy').length && (target == 'buy' || target == null)) {
                $('.frontPage--buy').css('visibility', 'visible').hide().fadeIn('slow');
            }
            // Read more button
            if ($('.frontPage--readmore').length && (target == 'readmore' || target == null)) {
                $('.frontPage--readmore').css('visibility', 'visible').hide().fadeIn('slow');
            }
        }

        function localizeWeekday(int) {

            let str = '';

            switch (parseInt(int)) {
                case 1:
                    str = 'Mon';
                    break;
                case 2:
                    str = 'Tue';
                    break;
                case 3:
                    str = 'Wed';
                    break;
                case 4:
                    str = 'Thu';
                    break;
                case 5:
                    str = 'Fri';
                    break;
                case 6:
                    str = 'Sat';
                    break;
                case 7:
                    str = 'Sun';
                    break;
                default:
            }

            return localize[str];
        }

        /* Google event tracking */

        var ga = window[window['GoogleAnalyticsObject'] || 'ga'];

        $('body').on('click', 'a[data-event-action]', function (e) {

            //e.preventDefault();

            var eventAction = $(this).data('event-action');
            var eventLabel = $(this).data('event-label');
            var eventValue = $(this).data('event-value');

            if (eventAction && eventLabel) {

                if (eventValue) {
                    if (window.ga && typeof ga === 'function') {
                        //console.log('send', 'event', 'Ticket', eventAction, eventLabel, eventValue);
                        ga('send', 'event', 'Ticket', eventAction, eventLabel, parseInt(eventValue));
                    }

                } else {
                    if (window.ga && typeof ga === 'function') {
                        //console.log('send', 'event', 'Ticket', eventAction, eventLabel);
                        ga('send', 'event', 'Ticket', eventAction, eventLabel);
                    }

                }
            }

        });

        /* */

        function handleFirstTab(e) {
            if (e.keyCode === 9) { // the "I am a keyboard user" key
                document.body.classList.add('user-is-tabbing');
                window.removeEventListener('keydown', handleFirstTab);
            }
        }

        window.addEventListener('keydown', handleFirstTab);

        let robustNext = null;
        let robustNextCount = 15;
        let filterInProgress = false;
        let dataFetchInProgress = false;
        let waypointCalendarDays = null;

        // Safari, in Private Browsing Mode, looks like it supports localStorage but all calls to setItem
        // throw QuotaExceededError. We're going to detect this and just silently drop any calls to setItem
        // to avoid the entire page breaking, without having to do a check at each usage of Storage.
        if (typeof localStorage === 'object') {
            try {
                localStorage.setItem('localStorage', 1);
                localStorage.removeItem('localStorage');
            } catch (e) {
                Storage.prototype._setItem = Storage.prototype.setItem;
                Storage.prototype.setItem = function () {
                };
            }
        }

        /* Accordion */

        /*eslint-disable */
        // let  accordions = document.querySelectorAll('.element-accordion-container'), ii;
        // for (ii = 0; ii < accordions.length; ++ii) {
        //   new Accordion(accordions[ ii ], {modal: false});
        // }
        let accordions;

        function initializeAccordions() {
            let accordions = document.querySelectorAll('.element-accordion-container'), ii;
            accordions.forEach(function (accordion) {
                for (ii = 0; ii < accordions.length; ++ii) {
                    new Accordion(accordions[ii], {modal: false});
                }
                //console.log('Initializing accordion:', accordion);
            });
        }

        initializeAccordions();
        /*eslint-enable */

        $('.element-accordion-container .element-accordion-single').on('keypress click', function (e) {
            if (e.which === 13 || e.type === 'click') {
                if ($(this).hasClass('open')) {
                    $(this).removeClass('state--no-overflow');
                } else {
                    $(this).addClass('state--no-overflow');
                }
            }
        });

        /**
         * HKT ACCORDION
         */
        const screenMobileWidthLimit = 1024; // Mobile break point
        function initializeHKTAccordions(caller) {

            // console.log('%c initializeHKTAccordions %o', 'color:cyan', caller);

            let $hktAccordions = $('.hkt-accordion');
            let functionCaller = caller !== undefined ? caller : 'common';
            const animationOffset = 140;
            let accordionVisibleItems = 4;
            let accordionItemThreshold = 2;
            let accordionTotalItems = 0;

            // Loop accordion element set
            $hktAccordions.each(function (index, accordion) {
                // Set current accordion
                //console.log('%c Mobile only accordion', 'color:yellow', $(accordion));

                let $AccordionItems = $(accordion).find('.hkt-accordion--item');

                if (!$AccordionItems.length) {
                    return false;
                }

                // Loop current accordion items
                $AccordionItems.each(function (index, accordionItem) {

                    if (!accordionItem.length) {

                        // Current accordion item
                        let $thisAccordionItem = $(accordionItem);
                        let $thisAccordionItemContent = $thisAccordionItem.find('.hkt-accordion--item-inner');

                        // --
                        // Check if mobile only accordion. Used in Show pages at least.
                        // --
                        if ($thisAccordionItem.hasClass('mobile-only')) {

                            // If there are overrides, use the override values
                            accordionVisibleItems = parseInt($thisAccordionItem.attr('data-items-visible') || accordionVisibleItems, 10);
                            accordionItemThreshold = parseInt($thisAccordionItem.attr('data-items-threshold') || accordionItemThreshold, 10);
                            accordionTotalItems = parseInt($thisAccordionItem.attr('data-items-total') || accordionTotalItems, 10);
                            let accordionTotalThresholdItems = (accordionVisibleItems + accordionItemThreshold); // If the combined amount is not surpassed, don't use the accordion.
                            let triggerButtonContainer = $thisAccordionItem.find('.hkt-accordion--item-trigger-container');
                            let hiddenClass = 'hidden';
                            let noPaddingClass = 'no-padding-bottom';

                            // If mobile screen width AND if the total amount is greater than the wanted visible items and threshold combined
                            if ($(window).width() < screenMobileWidthLimit && accordionTotalItems > accordionTotalThresholdItems) {
                                $thisAccordionItemContent.hide(0);
                                // Exclude gallery since it has it's own settings
                                $(accordionItem).find('.hkt-accordion--item-trigger').not('.gallery').show();
                                // Allow the button to show
                                triggerButtonContainer.removeClass(hiddenClass);
                                // Remove the correction padding on desktop because of the translate offsets
                                $thisAccordionItemContent.addClass(noPaddingClass);
                            } else {
                                $thisAccordionItemContent.show(0);
                                $(accordionItem).find('.hkt-accordion--item-trigger').not('.gallery').hide();
                                // Hide the button
                                triggerButtonContainer.addClass(hiddenClass);
                                // allow the correction padding on desktop
                                $thisAccordionItemContent.removeClass(noPaddingClass);
                            }
                        } else {
                            $thisAccordionItemContent.hide(0);
                        }
                        //-- .END check if mobile only accordion

                        // OnClick, Keypress Events
                        $(accordionItem).find('.hkt-accordion--item-trigger').not('.gallery').on('keypress click', function (e) {

                            let $this = $(this); //$(e.target);
                            let closeText = $this.attr('data-close-text');
                            let openText = $this.attr('data-open-text');
                            let $thisSpan = $this.find('span');

                            // Change text
                            if (e.which === 13 || e.type === 'click') {
                                // If Open, Close it
                                if ($thisAccordionItem.hasClass('open')) {
                                    $thisAccordionItem.removeClass('open');
                                    $thisAccordionItemContent.slideUp('slow', function () {
                                        $thisSpan.text(openText);
                                    });

                                    let $scrollTarget = $thisAccordionItem.parents('.parent-section').length ? $thisAccordionItem.parents('.parent-section') : $thisAccordionItem.parents('section');

                                    // Scroll back to element start
                                    $('html, body').animate({
                                        scrollTop: $scrollTarget.offset().top - animationOffset,
                                    }, 400);

                                    return false;

                                } else {
                                    // If Closed, Open it
                                    $thisAccordionItem.addClass('open');
                                    $thisAccordionItemContent.slideDown('slow', function () {
                                        // Change text
                                        $thisSpan.text(closeText);
                                    });

                                    return false;
                                }
                            }
                        });

                    }
                });
            });

            return false;
        }// .END

        //Initialize directly (remember to call again after ajax)
        // initializeHKTAccordions();
        // .END - HKT ACCORDION

        // Alert
        let hkt_alert = JSON.parse(localStorage.getItem('hkt_alert_timestamp'));
        let hkt_expire = null;

        if (hkt_alert !== null) {
            hkt_expire = parseInt(hkt_alert.timestamp + 2 * 60 * 60 * 1000); // 2 hours
        }

        let hkt_now = parseInt(new Date().getTime().toString());

        if (hkt_alert === null || hkt_expire < hkt_now) {
            $('section.hkt--alert').removeClass('state-hidden'); //Show Alert
        }

        $('span.hkt--alert-close').click(function () {

            $('section.hkt--alert').addClass('state-hidden');

            localStorage.setItem('hkt_alert_timestamp', JSON.stringify({timestamp: new Date().getTime()}));

        });

        // !!!

        //$('body.home section.hkt--alert').removeClass('state-hidden'); //Show Alert on home page

        // Misc stuff

        $('table.tablepress').stacktable();

        $('.show--dates-people a').attr('target', '_blank');

        $('.link-button.select-populated').click(function () {
            $('.populate-posts select').val($(this).data('target'));
        });

        $('.link-button.select-populated-text').click(function () {
            $('.populate-posts-text input').first().val($(this).data('target'));
        });

        $('.hero--share-trigger').click(function (e) {
            e.preventDefault();
            $(this).addClass('state-hidden');
        });

        $('.robust-calendar--content, .calendar, .show--calendar').on('click', 'a.sold-out', function (e) {
            e.preventDefault();
        });

        // 2017/2018 vendor changeover

        $('a.both-vendors').click(function (e) {
            e.preventDefault();

            $(this).closest('.visual-show--image').append('<div class="tickets-overlay utm-out"><div class="tickets-container"><a href="' + $(this).data('early') + '"><strong>' + localize['Shows in 2017'] + '</strong> <span>' + localize['Buy tickets at Ticketmaster'] + ' »</span></a> <a href="' + $(this).data('late') + '"><strong>' + localize['Shows in 2018'] + '</strong> <span>' + localize['Buy tickets at Lippu.fi'] + ' »</span></a></div></div>');

            var that = $(this);
            setTimeout(function () {
                that.closest('.visual-show--image').addClass('state--vendors-active');
            }, 150);

            $(this).fadeOut(300);

        });

        // Last minute deals ("Äkkilähtö")

        $('a.last-minute-overlay').each(function (index) {

            $(this).closest('.visual-show--image').append('<div class="tickets-overlay utm-out" style="background: rgba(34,34,34, 0.9)"><div class="tickets-container"><a href="' + $(this).data('last-minute-url') + '"><strong>' + localize['Last minute deal today'] + '!</strong> <span>' + localize['Buy tickets for half the price'] + ' »</span></a></div></div>');
            var that = $(this);
            setTimeout(function () {
                that.closest('.visual-show--image').addClass('state--vendors-active');
            }, 150);

        });

        // Lazy loads

        $('.grid-item img').lazyload();
        $('body.post-type-archive-show .show--list-item a').lazy();

        /* Comments */

        var comments_parent = $('.comments--messages');
        var comments_length = $('.comments--messages .comments--message').length;

        /*
         for (var i = 0; i < comments_length; i=i+10) {
         $('.comments--messages .comments--message').slice(i,i+10).wrapAll('<div class="comments--chunk"></div>');
         }

         var comment_chunks = $('.comments--chunk');

         while (comment_chunks.length) {
         comments_parent.append(comment_chunks.splice(Math.floor(Math.random() * comment_chunks.length), 1)[0]);
         }

         $('.comments--chunk').slice(1).hide().addClass('comments--message-hidden');
         */

        if (comments_length > 10) {
            comments_parent.append('<p><a href="#" class="load-more-comments">' + localize['Load more comments'] + '</a></p>');
        }

        comments_parent.on('click', 'a.load-more-comments', function (e) {
            e.preventDefault();
            $('.comments--message-hidden').first().show().removeClass('comments--message-hidden');

            if ($('.comments--message-hidden').length === 0) {
                $(this).remove();
            }

        });

        /* */

        /*
         $('a.site--mobile-trigger').click(function(e){
         e.preventDefault();
         $('.site--navigation').toggleClass('state-visible');
         $(this).find('i').toggleClass('fa-menu fa-close');
         });
         */

        $('.banner--mobile-navigation').on('click', 'a.site--mobile-trigger', function (e) {
            e.preventDefault();
            $('.banner--navigation').toggleClass('state-visible');
            $(this).find('i').toggleClass('fa-menu fa-close');
        });

        $('.show--featured, .show--other').on('click', 'a', function (e) {
            e.preventDefault();
        });

        /**
         * Hero Trailer
         */
        // Magnific-popup documentation: https://dimsemenov.com/plugins/magnific-popup/documentation.html
        $('.hero--trailer a').magnificPopup({
            type: 'iframe',
            //removalDelay: 500, //delay removal by X to allow out-animation
            iframe: {
                markup: '<div class="mfp-iframe-scaler">' +
                    '<div class="mfp-close"></div>' +
                    '<div id="hero--trailer-video--wrap"><iframe id="hero--trailer-video" class="mfp-iframe" frameborder="0" allowfullscreen></iframe></div>' +
                    '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button

                patterns: {
                    youtube: {
                        index: 'youtube.com/',
                        id: 'v=',
                        src: '//www.youtube.com/embed/%id%?autoplay=1&enablejsapi=1&rel=0',
                    },
                },
            },
            callbacks: {
                beforeOpen: function () {
                    // just a hack that adds mfp-anim class to markup
                    this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                    this.st.mainClass = 'mfp-zoom-out';
                },
                open: function () {
                    /*eslint-disable */
                    let player;
                    let playerFrame = document.getElementById("hero--trailer-video");

                    // On player state change handler.
                    function onPlayerStateChange(event) {
                        // Close popup when video ends
                        if (event.data === YT.PlayerState.ENDED) {
                            $.magnificPopup.close(); // Close magnificPopup
                        }
                    }

                    // Check if the YouTube API is already loaded.
                    if (typeof YT !== 'undefined' && YT.Player) {
                        player = new YT.Player(playerFrame, {
                            events: {
                                'onStateChange': onPlayerStateChange,
                            },
                        });
                    } else {
                        // If not loaded yet, define the global callback.
                        window.onYouTubeIframeAPIReady = function () {
                            player = new YT.Player(playerFrame, {
                                events: {
                                    'onStateChange': onPlayerStateChange,
                                },
                            });
                        };
                    }
                    /*eslint-enable */
                },
                beforeClose: function () {

                },
                afterClose: function () {

                },
            },
            closeOnContentClick: true,
            midClick: true,
        });

        // People popup

        $('.show--featured a.has-popup, .show--other a.has-popup').magnificPopup({
            type: 'inline',
            //removalDelay: 500, //delay removal by X to allow out-animation
            callbacks: {
                beforeOpen: function () {
                    // just a hack that adds mfp-anim class to markup
                    this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                    this.st.mainClass = 'mfp-zoom-out';

                    // Popup population
                    var $this = this.st.el;

                    var popup_shows = $this.data('shows');
                    var popup_shows_string = '';
                    var popup_html = '';

                    popup_shows.forEach(function (element) {
                        popup_shows_string += '<li><a href="' + element.url + '">' + element.title + '</a></li>';
                    });

                    popup_html = '<div class="popup-image"><img src="' + $this.data('image') + '" alt=""></div><div class="popup-content"><h4>' + $this.data('name') + '</h4><p>' + $this.data('name') + ' ' + localize['is involved with the following productions'] + ':</p><ul>' + popup_shows_string + '</ul></div>';

                    $('.people-show-more').html(popup_html);

                },
            },
            closeOnContentClick: true,
            midClick: true,
        });

        // Calendar AJAX stuff

        /**
         * Event location map
         *
         * @param event_venue_original  The venue name to match
         * @returns String              Either the mapped string or the original string
         */
        function map_event_venue(event_venue_original) {
            // Mapping
            const locations_map = {
                'Helsingin Kaupunginteatteri, Suuri näyttämö': 'Suuri näyttämö',
                'Arena-näyttämö, Hämeentie 2': 'Arena-näyttämö',
                'Helsingin Kaupunginteatteri, Pieni näyttämö': 'Pieni näyttämö',
                'Studio Pasila': 'Studio Pasila',
                'Lilla Teatern': 'Lilla Teatern',
                'Suuri näyttämö LÄMPIÖ': 'Suuren näyttämön lämpiö',
                'Pieni Näyttämö LÄMPIÖ': 'Pienen näyttämön lämpiö',
                'Studio Pasila LÄMPIÖ': 'Studio Pasilan lämpiö',
            };

            // Check if the key exists in the associative array
            // if (locations_map.hasOwnProperty(event_venue_original)) {
            if (Object.prototype.hasOwnProperty.call(event_venue_original)) {
                // Return the corresponding value
                return locations_map[event_venue_original];
            } else {
                // If key doesn't exist, return the original key string
                return event_venue_original;
            }

        }//. END map_event_venue

        // #calendar-all
        if ($('#calendar-all').length) {

            $.getJSON('/wp-json/tickets/all', function (response) {

                //console.log('%c response %d', 'color:cyan', response);

                var first_date = null;

                $.each(response, function (key, val) {
                    if (val[1] == 0) {
                        $('#calendar-all td.this-month[data-date="' + val[0] + '"]').addClass('events-full');
                    } else {
                        $('#calendar-all td.this-month[data-date="' + val[0] + '"]').addClass('events');
                        if (first_date === null) {
                            first_date = val[0];
                        }
                    }
                });

                if (first_date) {
                    $('#calendar-all td.this-month[data-date="' + first_date + '"]').trigger('click');
                }

                $('#calendar-all .calendar--view-month').each(function (index) {
                    var firstMonthWithData = $('#calendar-all td.events').first().closest('.calendar--view-month').index();
                    var lastMonthWithData = $('#calendar-all td.events').last().closest('.calendar--view-month').index();
                    if (index < firstMonthWithData || index > lastMonthWithData) {
                        $(this).addClass('to-be-removed');
                    }
                });

                $('#calendar-all .to-be-removed').remove();

                if ($('.calendar--view-months').hasClass('slick-initialized')) {
                    $('.calendar--view-months')[0].slick.refresh();
                }

            });

            /**
             * Calendar on front page (Maybe somewhere else as well?)
             */
            $('#calendar-all').on('click', 'td.events', function (e) {
                e.preventDefault();

                $('#calendar-all em.state--selected').each(function (index) {
                    $(this).closest('span').html($(this).closest('span').text());
                });

                $(this).find('span').wrapInner('<em class="state--selected"></em>');

                $('section.calendar .show--list, section.calendar .calendar--meta-date').addClass('state-hidden');

                $('section.calendar .show--list').append('<div class="show--calendar-all--loader"></div>');

                $.getJSON('/wp-json/tickets/date/' + $(this).data('date') + '/', function (response) {

                    $('section.calendar .calendar--meta-date').text(localize[response.weekday] + ' ' + response.date);

                    $('section.calendar .show--list').empty();

                    $.each(response.events, function (key, event_val) {

                        var event_wordpress = '';
                        var event_image = '';
                        var emphasis_class = '';
                        var emphasis_text = '';
                        var html = '';
                        var event_wordpress_category = '';
                        // Venue name mapped
                        let event_venue = map_event_venue(event_val.venue);

                        // //console.log('%c event_val.availability %s', 'color:orange', event_val.availability);
                        // //console.log('%c event_val.wordpress_even %s', 'color:red', event_val.wordpress_even);

                        if (event_val.wordpress_event) {
                            // //console.log('%c if -> event_val.wordpress_even %s', 'color:green', event_val.wordpress_even);

                            event_wordpress = event_val.wordpress;

                            // Event categories
                            if (event_wordpress['event_category']) {
                                event_wordpress_category = event_wordpress['event_category'];
                            } else {
                                event_wordpress_category = localize['Event'];
                            }

                            if (event_wordpress['image']) {
                                event_image = event_wordpress['image']['size-360'];
                            }

                            html = '<article class="show--list-item"><div class="container"><a href="' + event_wordpress.link + '" class="show--list-item-image" style="background-image: url(' + event_image + ')"></a><div class="show--list--item-content"><strong>' + event_wordpress_category + '</strong><h2><a href="' + event_wordpress.link + '">' + event_wordpress.title + '</a></h2><p>' + event_wordpress.formatted_time + '</p></div></div></article>';
                            // Frontpage buttons
                            frontpageBtnAnimation();
                        } else {
                            //console.log('%c else -> event_val.wordpress_even %s', 'color:green', event_val.wordpress_even);

                            event_wordpress = JSON.parse(event_val.wordpress);

                            if (event_wordpress != null) {

                                if (event_wordpress['featured_image']) {
                                    event_image = event_wordpress['featured_image']['size-360'];
                                }

                                if (event_val.availability === 'none' || Boolean(event_val.event_is_manually_sold_out)) {
                                    emphasis_class = ' sold-out';
                                    emphasis_text = localize['Sold-out'] || 'Sold-out';

                                } else if (event_val.availability === 'limited' || event_val.availability === 'low') {
                                    emphasis_class = ' filling-up';
                                    emphasis_text = localize['Filling up'] + ' – ' + localize['Buy tickets'];
                                    // Frontpage buttons
                                    frontpageBtnAnimation();
                                } else {
                                    emphasis_class = '';
                                    emphasis_text = localize['Buy tickets'];
                                    // Frontpage buttons
                                    frontpageBtnAnimation();
                                }

                                var specialShow = event_val.special ? ' (' + event_val.special + ')' : '';

                                html = '<article class="show--list-item"><div class="container"><a data-event-action="PerformanceFrontCalendar" data-event-label="' + event_wordpress.title_caps + '" data-event-value="' + event_val.timestamp + '" href="' + event_val.url + '" class="utm-out show--list-item-image' + emphasis_class + '" style="background-image: url(' + event_image + ')"></a><div class="show--list--item-content"><strong>' + event_wordpress.title_over + '</strong><h2><a data-event-action="PerformanceFrontCalendar" data-event-label="' + event_wordpress.title_caps + '" data-event-value="' + event_val.timestamp + '" href="' + event_val.url + '" class="utm-out ' + emphasis_class + '">' + event_wordpress.title + specialShow + '</a></h2><p>' + event_val.time + ', <span>' + event_venue + '</span> &ndash; ' + event_wordpress.address + '</p></div><div class="show--list--item-tickets utm-out"><a data-event-action="PerformanceFrontCalendar" data-event-label="' +
                                    event_wordpress.title_caps +
                                    '" data-event-value="' + event_val.timestamp + '" href="' + event_val.url + '" class="btn' + emphasis_class + '">' + emphasis_text + '</a></div></div></article>';

                            }

                        }

                        $('section.calendar .show--list').append(html);

                    });

                }).fail(function () {
                    // Readmore button as default
                    frontpageBtnAnimation('readmore');

                }).always(function () {

                    $('section.calendar .show--list .show--calendar-all--loader').remove();

                    setTimeout(function () {
                        $('section.calendar .show--list, section.calendar .calendar--meta-date').removeClass('state-hidden');
                        /* eslint-disable */
                        // Call the Knowit UTMGrabber plugin method
                        if (typeof KnowitUTMG !== 'undefined' && typeof KnowitUTMG.addParams === 'function') {
                            KnowitUTMG.addParams(true);
                        } else {
                            console.log('KnowitUTMG.addParams could not be added in common.js');
                        }
                    }, 100);

                });

            });

        }

        // #calendar-robust-all
        function RobustFilterResults() {

            filterInProgress = true;

            var category_array = [];
            $('.show--calendar-filters a').each(function () {
                var category = $(this).data('category-filter');
                if ($(this).hasClass('state-active')) {
                    category_array.push(category);
                }
            });

            var areaHeight = $('.robust-calendar--content').height();

            $('.robust-calendar--content').css('height', areaHeight);

            $('.robust-calendar--content .show--grid-item').removeClass('state-visible');

            $('.robust-calendar--content .show--grid-item').each(function () {
                $(this).changeElementType('div');
            });

            for (var i = 0; i < category_array.length; i++) {
                $('.robust-calendar--content .show--grid-item[data-category*="' + category_array[i] + '"]').each(function () {
                    $(this).addClass('state-visible');
                    $(this).changeElementType('article');
                });
            }

            $('.robust-calendar--day').removeClass('state-hidden');

            $('.robust-calendar--day').each(function () {
                var events = $(this).find('.show--grid-item.state-visible').length;
                if (events === 0) {
                    $(this).addClass('state-hidden');
                }
            });

            $('.robust-calendar--content').css('height', 'auto');

            window.dispatchEvent(new Event('resize')); // widget waypoints need this

            filterInProgress = false;

            var visibleDays = $('.robust-calendar--day:not(.state-hidden)').length;
            if (visibleDays < 3) {

                $('<div class="robust-calendar--loader-loading-relative"><div class="robust-calendar--loader state-visible">Loading...</div></div>').appendTo('.robust-calendar--content');
                GetRobustData(robustNext, robustNextCount);
                /*
                 if( $('.robust-calendar--content').height() < 999  && robustNext != null) {
                 GetRobustData(robustNext, robustNextCount);
                 }
                 */

            }

        }

        function HideRobustArea() {

            $('html, body').animate({
                scrollTop: 0,
            }, 600);

            $('.robust-calendar--content-container').removeClass('state-visible');
            $('.robust-calendar--loader').addClass('state-visible');

        }

        function ShowRobustArea() {

            $('.robust-calendar--content-container').addClass('state-visible');
            $('.robust-calendar--loader').removeClass('state-visible');

            /*
             if(waypointCalendarDays && waypointCalendarDays.length > 0) {
             //console.log(waypointCalendarDays);

             waypointCalendarDays = [];

             //console.log('waypointCalendarDays destroyed');
             }

             waypointCalendarDays = $('.robust-calendar--day-content article.show--grid-item:last-child').waypoint(function(direction) {
             //var waypointID = this.element.id;
             if(direction === 'down') {
             //console.log('down');
             $(this.element).closest('.robust-calendar--day').find('.robust-calendar--day-date').css('position','absolute');

             //hajet tähän et:n malliin etäisyyden ylhäältä ja se ois sit siinä..

             //console.log($(this).data('date'));
             } else {
             //console.log('up');
             $(this.element).closest('.robust-calendar--day').find('.robust-calendar--day-date').css('position','fixed');
             }
             }, {
             offset: 0,
             });
             */

        }

        /**
         * Get data for the calendar
         * (In use on calendar page)
         *
         * @param robust_date
         * @param robust_count
         * @constructor
         */
        function GetRobustData(robust_date, robust_count) {

            dataFetchInProgress = true;

            $.getJSON('/wp-json/tickets/date/' + robust_date + '/' + robust_count + '/', function (response) {

                if (robustNext != null) {

                    $('#calendar-robust-all em.state--selected').each(function (index) {
                        $(this).closest('span').html($(this).closest('span').text());
                    });

                    $('#calendar-robust-all td.events[data-date*="' + Object.keys(response.data)[0] + '"]').find('span').wrapInner('<em class="state--selected"></em>');

                    var dateSlideIndex = $('#calendar-robust-all td.events[data-date*="' + Object.keys(response.data)[0] + '"]').closest('.slick-slide').index();

                    $('.show--calendar-months').slick('slickGoTo', dateSlideIndex);

                }

                robustNext = response.next;

                $.each(response.data, function (key, val) {

                    var html_header = '<div class="robust-calendar--day" data-date="' + val['date-ymd'] + '"><div class="robust-calendar--day-date"><span class="robust-calendar--day-date-weekday">' + localize[val.weekday] + '</span><div class="robust-calendar--day-date-daymonth"><strong>' + val.date.split('.')[0] + '</strong><em>' + localize[val.month] + '</em></div></div><div class="robust-calendar--day-content">';

                    var html_footer = '</div></div>';

                    var event_date = val.date;

                    var html_content = '';

                    $.each(val.events, function (key, event_val) {

                        var event_wordpress = '';
                        var emphasis_class = '';
                        var emphasis_text = '';
                        var event_image = '';
                        var html = '';
                        var event_wordpress_category = '';
                        // Venue name mapped
                        let event_venue = map_event_venue(event_val.venue);

                        if (event_val.wordpress_event) {

                            event_wordpress = event_val.wordpress;

                            if (event_wordpress['image']) {
                                event_image = event_wordpress['image']['size-360'];
                            }

                            // Event categories
                            if (event_wordpress['event_category']) {
                                event_wordpress_category = event_wordpress['event_category'];
                            } else {
                                event_wordpress_category = localize['Event'];
                            }

                            html_content += '<article class="show--grid-item state-visible" data-category="event"><div class="show--grid-item-image" style="background-image: url(' + event_image + ')"><a class="overlay utm-out" href="' + event_wordpress.link + '">' + event_wordpress.title + '</a></div><div class="show--grid--item-content"><strong>' + event_wordpress_category + '</strong><h2><a href="' + event_wordpress.link + '">' + event_wordpress.title + '</a></h2><p><span><i class="fa fa-clock" aria-hidden="true"></i> <em>' + event_wordpress.formatted_date + ' </em> ' + event_wordpress.formatted_time + '</span></p></div></article>';

                        } else {

                            event_wordpress = JSON.parse(event_val.wordpress);

                            if (event_wordpress != null) {

                                if (event_wordpress['featured_image']) {
                                    event_image = event_wordpress['featured_image']['size-360'];
                                }

                                if (event_val.availability === 'none' || event_val.event_is_manually_sold_out) {
                                    emphasis_class = ' sold-out';
                                    emphasis_text = localize['Sold-out'];
                                } else if (event_val.availability === 'limited' || event_val.availability === 'low') {
                                    emphasis_class = ' filling-up';
                                    emphasis_text = localize['Filling up'] + ' – ' + localize['Buy tickets'];
                                } else {
                                    emphasis_class = '';
                                    emphasis_text = localize['Buy tickets'];
                                }

                                var specialShow = event_val.special ? ' (' + event_val.special + ')' : '';

                                var event_categories = event_wordpress && event_wordpress.categories ? event_wordpress.categories.join() : '';

                                html_content += '<article class="show--grid-item state-visible" data-category="' + event_categories + '"><div class="show--grid-item-image" style="background-image: url(' + event_image + ')"><a class="overlay utm-out" href="' + event_wordpress.url + '">' + localize['Buy tickets'] + '</a><a data-event-action="Calendar" data-event-label="' + event_wordpress.title_caps + '" data-event-value="' + event_val.timestamp + '" class="utm-out emphasis ' + emphasis_class + '" href="' + event_val.url + '">' + emphasis_text + '</a></div><div class="show--grid--item-content"><strong>' + event_wordpress.title_over + '</strong><h2><a data-event-action="Calendar" data-event-label="' + event_wordpress.title_caps + '" data-event-value="' + event_val.timestamp + '" href="' + event_wordpress.url + '" class="utm-out">' + event_wordpress.title + specialShow + '</a></h2><p><span><i class="fa fa-clock" aria-hidden="true"></i> <em>' + event_date + ' </em> ' + event_val.time +
                                    '</span><span><i class="fa fa-map-marker" aria-hidden="true"></i> ' + event_venue + '</span></p></div></article>';

                            }

                        }

                    });

                    var html_all = html_header + html_content + html_footer;

                    $(html_all).hide().appendTo('section.robust-calendar .robust-calendar--content-container').fadeIn(300);
                    //$(html_all).appendTo('section.robust-calendar .robust-calendar--content-container');

                });

            }).fail(function () {
                //console.log('API Error');
            }).always(function () {
                $('.robust-calendar--content .robust-calendar--loader-loading-relative').remove();

                setTimeout(function () {
                    $('section.calendar .show--list, section.calendar .calendar--meta-date').removeClass('state-hidden');
                    /* eslint-disable */
                    // Call the Knowit UTMGrabber plugin method
                    if (typeof KnowitUTMG !== 'undefined' && typeof KnowitUTMG.addParams === 'function') {
                        KnowitUTMG.addParams(true);
                    } else {
                        console.log('KnowitUTMG.addParams could not be added in common.js');
                    }
                }, 100);

                /*
                 setTimeout(function(){
                 RobustFilterResults();
                 }, 330);
                 */
                RobustFilterResults();

                dataFetchInProgress = false;
            });

        }

        if ($('#calendar-robust-all').length) {

            $('.robust-calendar-navigation-mobile-trigger').click(function (e) {
                e.preventDefault();

                $('.robust-calendar--widget').toggleClass('state-pre-visible');
                setTimeout(function () {
                    $('.robust-calendar--widget').toggleClass('state-visible');
                }, 50);

                $('.show--calendar-months')[0].slick.refresh();

                $(this).toggleClass('state-active');

            });

            // Robust calendar filters

            // http://stackoverflow.com/questions/8584098/how-to-change-an-element-type-using-jquery
            (function ($) {
                $.fn.changeElementType = function (newType) {
                    var attrs = {};

                    $.each(this[0].attributes, function (idx, attr) {
                        attrs[attr.nodeName] = attr.nodeValue;
                    });

                    this.replaceWith(function () {
                        return $('<' + newType + '/>', attrs).append($(this).contents());
                    });
                };
            })(jQuery);

            $('.show--calendar-filters a').click(function (e) {
                e.preventDefault();

                //Älä anna disabloida viimeistä
                var actives = $('.show--calendar-filters a.state-active').length;
                if ($(this).hasClass('state-active') && actives < 2) {
                    return;
                }

                //Älä anna filtteröidä, jos filtteröinti on jo kesken
                if (filterInProgress === true) {
                    return;
                }

                $(this).toggleClass('state-active');

                HideRobustArea();

                setTimeout(function () {

                    //Remove past data when filtering, if the user has scrolled down and received more data
                    var cutoff = parseInt($('.show--calendar em.state--selected').closest('td.events').data('date').replace(/\D/g, ''));

                    $('.robust-calendar--day').each(function () {
                        var thisDate = parseInt($(this).data('date').replace(/\D/g, ''));
                        if (thisDate < cutoff) {
                            $(this).remove();
                        }
                    });

                    RobustFilterResults();
                }, 330);

                setTimeout(function () {
                    ShowRobustArea();
                }, 630);

            });

            var waypointEndlessCalendar = $('#calendar-robust').waypoint(function (direction) {
                var waypointID = this.element.id;

                if ($('.robust-calendar--loader').hasClass('state-visible') === false) {
                    if (direction === 'down') {
                        if (robustNext != null && dataFetchInProgress === false) {

                            $('<div class="robust-calendar--loader-loading-relative"><div class="robust-calendar--loader state-visible">Loading...</div></div>').appendTo('.robust-calendar--content');

                            GetRobustData(robustNext, robustNextCount);

                        } else {
                            //console.log('No more data / Fetch in progress');
                        }
                    }
                }

            }, {
                offset: function () {
                    return -this.element.clientHeight + 999;
                },
            });

            var waypointFooter = $('footer').waypoint(function (direction) {

                if (direction === 'down') {
                    $('.robust-calendar--widget-content').addClass('state-stay-behind');
                } else {
                    $('.robust-calendar--widget-content').removeClass('state-stay-behind');
                }

            }, {
                offset: $('.robust-calendar--widget-content').height(),
            });

            $('#calendar-robust-all').on('click', 'td.events', function (e) {
                e.preventDefault();

                $('.robust-calendar--widget').removeClass('state-pre-visible state-visible');

                $('.robust-calendar--content-container').removeClass('state-visible');
                $('.robust-calendar--loader').addClass('state-visible');

                $('section.robust-calendar .robust-calendar--content-container').empty();

                GetRobustData($(this).data('date'), robustNextCount);
                HideRobustArea();

                setTimeout(function () {
                    ShowRobustArea();
                }, 630);

                $('#calendar-robust-all em.state--selected').each(function (index) {
                    $(this).closest('span').html($(this).closest('span').text());
                });

                $(this).find('span').wrapInner('<em class="state--selected"></em>');

                /*
                 if(robustNext != null) {
                 $('#calendar-robust-all td.this-month[data-date="' + robustNext + '"]').find('span').wrapInner('<em class="state--selected"></em>');
                 }
                 */

            });

            $.getJSON('/wp-json/tickets/all', function (response) {

                $.each(response, function (key, val) {
                    if (val[1] == 0) {
                        $('#calendar-robust-all td.this-month[data-date="' + val[0] + '"]').addClass('events events-full');
                    } else {
                        $('#calendar-robust-all td.this-month[data-date="' + val[0] + '"]').addClass('events');
                    }
                });

                //$('#calendar-robust-all td.this-month[data-date="' + response[0][0] + '"]').trigger('click');

                $('#calendar-robust-all td.events').first().trigger('click');

                //$('#calendar-robust-all td.this-month[data-date="2017-08-23"]').trigger('click');

                //console.log( $('#calendar-robust-all td.this-month[data-date="' + response[0][0] + '"]').nextAll('td.events').first().data('date') );

                // Tämä ei kertakaikkiaan vain toimi, en tiedä miksi. remove() ei suostu tässä toimimaan lainkaan, ei sitten millään
                // Noh kyse ei ole isosta ongelmasta, jätän näin
                /*
                 $('#calendar-robust-all .show--calendar-month').each(function( index ) {

                 var lastMonthWithData = $('#calendar-robust-all td.events').last().closest('.show--calendar-month').index();
                 if( index > lastMonthWithData) {
                 $(this).addClass('to-be-removed');
                 }
                 });

                 $('.show--calendar-months .to-be-removed').remove();
                 */

                var dateSlideIndex = $('#calendar-robust-all td.events[data-date*="' + response[0][0] + '"]').closest('.slick-slide').index();
                $('.show--calendar-months').slick('slickGoTo', dateSlideIndex);

                if ($('.show--calendar-months').hasClass('slick-initialized')) {
                    $('.show--calendar-months')[0].slick.refresh();
                }

            });

        }

        // Gallery

        /*eslint-disable */

        let $galleryGrid = $('.show--gallery div.grid');
        let $galleryGridItems = $galleryGrid.find('.grid-item');
        const $galleryBtn = $('.hkt-accordion--item-trigger.gallery');
        const galleryBtnOpenText = $galleryBtn.attr('data-open-text');
        const galleryBtnCloseText = $galleryBtn.attr('data-close-text');

        // let galleryVisibleItems = Number.isNaN(parseInt(accordions?.gallery_images_to_show)) ? 10 : parseInt(accordions.gallery_images_to_show);

        let galleryVisibleItems = (accordions && !isNaN(parseInt(accordions.gallery_images_to_show))) ? parseInt(accordions.gallery_images_to_show) : 10;

        let galleryItemTreshold = (accordions && !isNaN(parseInt(accordions.gallery_images_threshold))) ? parseInt(accordions.gallery_images_threshold) : 4;

        // Assign Masonry
        let $grid = $galleryGrid.masonry({
            columnWidth: '.grid-sizer',
            itemSelector: '.grid-item',
            gutter: '.gutter-sizer',
            percentPosition: true,
        });

        // If the overrides are set, use them over the general setting values
        if (accordions && accordions.overrides && accordions.overrides.accordion_override_gallery) {
            let parsedItemsToShow = parseInt(accordions.overrides.items_to_show__gallery);
            galleryVisibleItems = isNaN(parsedItemsToShow) ? galleryVisibleItems : parsedItemsToShow;

            let parsedItemThreshold = parseInt(accordions.overrides.item_threshold__gallery);
            galleryItemTreshold = isNaN(parsedItemThreshold) ? galleryItemTreshold : parsedItemThreshold;
        }
        // .END overrides

        // The total amount/threshold for the accordion to be enabled
        let galleryTotalItemsForAccordion = (galleryVisibleItems + galleryItemTreshold);

        // Hide gallery elements if the amount is more than desired visible items and the threshold combined
        if ($galleryGridItems.length > galleryTotalItemsForAccordion && $(window).width() < screenMobileWidthLimit) {

            // Hide the items after the galleryVisibleItems amount
            $galleryGridItems.each(function (index, el) {
                if (index > (galleryVisibleItems - 1)) { // deduct 1 since starts from 0
                    $(el).hide();
                }
            });

            // Gallery items hide/show button action
            $galleryBtn.on('keypress click', function () {
                const hiddenItems = $('.grid-item:hidden');
                const visibleItems = $('.grid-item:visible');
                const $thisTextSpan = $(this).find('span');

                if (hiddenItems.length === 0) {
                    // All items are visible, hide all but the first x items
                    $('.grid-item').slice(galleryVisibleItems).hide();
                    $thisTextSpan.text(galleryBtnOpenText);

                    // Scroll back to element start
                    $('html, body').animate({
                        scrollTop: $galleryGrid.offset().top,
                    }, 400);
                    // Toggle the open class on the button
                    $(this).removeClass('button--open');
                } else {
                    // Some items are hidden, show all items
                    hiddenItems.show();
                    $thisTextSpan.text(galleryBtnCloseText);
                    // Toggle the open class on the button
                    $(this).addClass('button--open');
                }

                $grid.masonry('layout');
            });
        } else {
            // Don't show the more button if there are x or less items
            $galleryBtn.hide();
        } // .END If more than wanted items

        $grid.masonry('layout');
        /*
         $grid.imagesLoaded().progress( function() {
         $grid.masonry('layout');
         });
         */

        $('.show--gallery div.grid a:not(.link-direct)').magnificPopup({
            type: 'image',
            removalDelay: 500, //delay removal by X to allow out-animation
            gallery: {
                enabled: true,
            },
            callbacks: {
                beforeOpen: function () {
                    // just a hack that adds mfp-anim class to markup
                    this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                    this.st.mainClass = 'mfp-zoom-out';
                    /*
                     $('.show--navigation').css('visibility','hidden');
                     $('.show--navigation').addClass('fadeout');
                     */
                },
                open: function () {

                },
                beforeClose: function () {
                    //$('.show--navigation').css('visibility','visible');
                },
                afterClose: function () {
                    //$('.show--navigation').removeClass('fadeout');
                },
            },
            closeOnContentClick: true,
            midClick: true,
        });

        /*eslint-enable */

        // Smooth scroll

        $(function () {
            $('a[href*="#"]:not([href="#"]):not([href="#date"]):not(.accessibility):not(.has-popup)').click(function () {
                if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    if (target.length) {
                        $('html, body').animate({
                            scrollTop: target.offset().top - 114,
                        }, 600);
                        return false;
                    }
                }
            });
        });

        $(function () {
            $('a[href="#date"]').click(function () {
                if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    if (target.length) {
                        $('html, body').animate({
                            scrollTop: target.offset().top,
                        }, 400);
                        return false;
                    }
                }
            });
        });

        // Single show calendar

        $('a.show--calendar-prev').addClass('disabled');
        $('span.show--calendar-month-name').text($('.show--calendar-month:first-child table').data('month'));

        $('.show--calendar-months').slick({
            dots: false,
            speed: 500,
            arrows: false,
            infinite: false,
        });

        if ($('#calendar-single').length) {

            $('.show--calendar-purchase').on('click', 'a', function (e) {
                if ($(this).closest('div').hasClass('disabled')) {
                    e.preventDefault();
                }
            });

            $.getJSON('/wp-json/tickets/show/' + tickets['post_id'] + '/', function (response) {
                // $.getJSON('/wp-json/hkt/ticket-list/' + tickets['post_id'] + '/', function (response) {

                var first_date = null;

                $.each(response, function (key, val) {
                    if (val[1] == 0 || val['event_is_manually_sold_out']) {
                        $('#calendar-single td.this-month[data-date="' + val[0] + '"]').addClass('events events-full');
                    } else {
                        $('#calendar-single td.this-month[data-date="' + val[0] + '"]').addClass('events');
                        if (first_date === null) {
                            first_date = val[0];
                        }
                    }
                });

                if (first_date) {

                    // Valitse ensimmäinen
                    $('#calendar-single td.this-month[data-date="' + first_date + '"]').trigger('click');

                    var firstSlickIndex = $('#calendar-single td.this-month[data-date="' + first_date + '"]').closest('.show--calendar-month').data('slick-index');
                    $('.show--calendar-months').slick('slickGoTo', firstSlickIndex);

                    $('.hero--buy').css('visibility', 'visible').hide().fadeIn('slow');

                } else {

                    // Kaikki esitykset on myyty loppuun
                    $('.show--calendar-purchase-buy-tickets').html('<span class="all-sold-out">' + localize['All shows have been sold-out.'] + '</span>');
                    $('.show--calendar-purchase-order-serving').html('');

                    $('.show--buy-secondary').html('<strong class="all-sold-out" style="color: #2bb976">' + localize['All shows have been sold-out.'] + '</strong>');
                    $('.hero--buy').html('');

                }

            });

            $('#calendar-single').on('click', 'td.events', function (e) {
                e.preventDefault();

                $('.show--calendar-purchase-buy-tickets, .show--calendar-purchase-order-serving').addClass('state-disabled');

                $('.show--calendar-purchase a span').addClass('state-hidden');

                $('.show--calendar-purchase-buy-tickets').append('<div class="show--calendar-purchase--loader"></div>');

                $('#calendar-single em.state--selected').each(function (index) {
                    $(this).closest('span').html($(this).closest('span').text());
                });

                $(this).find('span').wrapInner('<em class="state--selected"></em>');

                var events_full = $(this).hasClass('events-full');

                $.getJSON('/wp-json/tickets/show/' + tickets['post_id'] + '/' + $(this).data('date') + '/', function (response) {

                    if (response.length > 0) {

                        $('.show--calendar-purchase-buy-tickets').html('');
                        $('.show--calendar-purchase-order-serving').html('');

                        if (events_full) {
                            $('.show--calendar-purchase-order-serving').removeClass('state-disabled');
                        } else {
                            $('.show--calendar-purchase-buy-tickets, .show--calendar-purchase-order-serving').removeClass('state-disabled');
                        }

                        for (var i = 0; i < response.length; i++) {

                            if (events_full) {
                                $('.show--calendar-purchase-buy-tickets').html('<a href="#" class="sold-out"><span class="state-hidden">' + localize['Sold-out'] + '</span></a>');
                            } else {

                                if (response[i]['availability'] === '1' && response[i]['event_is_manually_sold_out'] !== 1) {

                                    $('.show--calendar-purchase-buy-tickets').append('<a data-event-action="PerformanceCalendar" data-event-label="' + localize.title + '" data-event-value="' + response[i]['timestamp'] + '" href="' + response[i]['url'] + '" target="_self"><span class="state-hidden">' + localize['Buy tickets'] + ' ' + response[i]['date'] + ' ' + response[i]['time'] + '</span></a>');
                                } else {
                                    $('.show--calendar-purchase-buy-tickets').append('<a href="#" class="sold-out"><span class="state-hidden">' + response[i]['date'] + ' ' + response[i]['time'] + ' ' + localize['Sold-out'] + '</span></a>');
                                }

                                if (response[i]['event_name'].indexOf('K18') !== -1 || response[i]['event_name'].indexOf('K-18') !== -1) {
                                    $('.show--calendar-purchase-buy-tickets').append('<span class="age-restrictions">' + localize['Minimum age 18+'] + '</span>');
                                }
                            }

                            var order_link = null;
                            var order_response_id = null;

                            if (tickets['venue'] == 26) {
                                order_link = 'http://www.cafetalo.fi/tilaatarjoilut';
                            } else {

                                order_response_id = response[i]['event_id'];
                                if (order_response_id.indexOf('tm-') !== -1) {
                                    order_response_id = order_response_id.replace('tm-', '');
                                }

                                order_link = 'http://shop.sodexo.fi/hkt/' + order_response_id + '/';
                            }

                            if ((tickets['ticketmaster_external_id'] == 970726 && response[i]['time'] == '22:00') || (response[i]['type'] !== 'tm')) {
                                // Hide if: Club ACT!ONE and time 22.00 || jos lippu.fi
                            } else {
                                $('.show--calendar-purchase-order-serving').append('<a href="' + order_link + '" target="_blank"><span class="state-hidden">' + localize['Order serving'] + ' ' + response[i]['date'] + ' ' + response[i]['time'] + '</span></a>');
                            }

                        }


                    } else {

                        $('.show--calendar-purchase-buy-tickets').html('<a href="#"><span class="state-hidden">' + localize['Buy tickets'] + '</span></a>');
                        $('.show--calendar-purchase-order-serving').html('<a href="#"><span class="state-hidden">' + localize['Order serving'] + '</span></a>');

                    }

                }).always(function () {

                    $('.show--calendar-purchase-buy-tickets .show--calendar-purchase--loader').remove();

                    setTimeout(function () {
                        $('.show--calendar-purchase a span').removeClass('state-hidden');
                        /* eslint-disable */
                        // Call the Knowit UTMGrabber plugin method
                        if (typeof KnowitUTMG !== 'undefined' && typeof KnowitUTMG.addParams === 'function') {
                            KnowitUTMG.addParams(true);
                        } else {
                            console.log('KnowitUTMG.addParams could not be added in common.js');
                        }
                    }, 100);

                    if ($('.show-calendar--widget.active-state').length) {

                        var number_of_shows_left = $('td.events').length;
                        number_of_shows_left = parseInt(number_of_shows_left);

                        if (number_of_shows_left < 6) {
                            $('.show--calendar-extra-info').html('<span class="coming-to-a-close">' + localize['Attention! The show is coming to a close.'] + '</span>');
                        }

                    }

                });

            });

        }

        /*eslint-disable */
        $('.show--calendar-months').on('afterChange', function (event, slick, currentSlide) {
            var month = $(this).find('.show--calendar-month:eq(' + currentSlide + ')').find('table').data('month');
            $('span.show--calendar-month-name').text(month);

            $('.show--calendar-controls a').removeClass('disabled');

            if (currentSlide === 0) {
                $('a.show--calendar-prev').addClass('disabled');
            }

            if ((currentSlide + 1) === $('.show--calendar-month').length) {
                $('a.show--calendar-next').addClass('disabled');
            }

            //$('.show--calendar').removeClass('init');

        });
        /*eslint-enable */

        $('.show--calendar-controls a').click(function (e) {
            e.preventDefault();

            if ($(this).hasClass('show--calendar-prev')) {
                $('.show--calendar-months').slick('slickPrev');
            } else {
                $('.show--calendar-months').slick('slickNext');
            }
        });

        // Seating chart pan&zoom

        $('.venue--seating-chart a').magnificPopup({
            type: 'image',
            removalDelay: 500, //delay removal by X to allow out-animation
            callbacks: {
                beforeOpen: function () {
                    // just a hack that adds mfp-anim class to markup
                    this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                    this.st.mainClass = 'mfp-zoom-out';
                    $('.show--navigation').css('visibility', 'hidden');
                    $('.show--navigation').addClass('fadeout');
                },
                open: function () {

                    var mouseFlag = 0;

                    $('.mfp-figure figure').css('padding', '40px 0');
                    $('.mfp-figure figure img.mfp-img').css('padding', '0');

                    $('.mfp-figure figure img.mfp-img').wrap('<span class="zoom-fx"></span>').css('display', 'block').parent().zoom({duration: 0});

                    $('.mfp-figure figure').mousemove(function () {
                        if (mouseFlag < 10) {
                            $(this).find('span.zoom-fx').trigger('mouseenter');
                            mouseFlag++;
                        }
                    });

                },
                beforeClose: function () {
                    $('.mfp-figure figure img.mfp-img').parent().trigger('zoom.destroy'); // remove zoom
                    $('.mfp-figure figure img.mfp-img').parent().trigger('mouseleave');
                    $('.show--navigation').css('visibility', 'visible');
                },
                afterClose: function () {
                    $('.show--navigation').removeClass('fadeout');
                },
            },
            closeOnContentClick: true,
            midClick: true,
        });

        // Sticky navigations

        /*eslint-disable */
        var waypointBlocks = $('.navigation-block').waypoint(function (direction) {
            var waypointID = this.element.id;

            //$('#date, #context--navigation ul li a').each(function( index ) {
            $('#context--navigation ul li a').each(function (index) {
                var href = $(this).attr('href');
                if (href.substring(1) === waypointID) {
                    $(this).parent('li').addClass('state-active');
                } else {
                    $(this).parent('li').removeClass('state-active');
                }
            });

        }, {
            offset: 120,
        });

        //var waypointNavigation = $('#date, #context--navigation').waypoint(function(direction) {
        var waypointNavigation = $('#context--navigation').waypoint(function (direction) {
            var waypointID = this.element.id;

            if (direction === 'down') {
                $('#' + waypointID).find('nav').addClass('state-fixed');
            } else {
                $('#' + waypointID).find('nav').removeClass('state-fixed');
            }

        }, {
            offset: 0,
        });

        var waypointNavigationCalendarWidget = $('#calendar-selector').waypoint(function (direction) {
            var waypointID = this.element.id;

            if (direction === 'down') {
                $('#' + waypointID).find('.robust-calendar--widget-content').addClass('state-fixed');
            } else {
                $('#' + waypointID).find('.robust-calendar--widget-content').removeClass('state-fixed');
            }

        }, {
            offset: 0,
        });

        /*eslint-enable */

        $('.calendar--action a').click(function () {
            //e.preventDefault();
            $('section.shows--all').toggleClass('state-hidden');
            $(this).closest('section').toggleClass('state-active');

            if ($('.calendar--view-months').hasClass('slick-initialized')) {
                $('.calendar--view-months')[0].slick.refresh();
            }

        });

        // AJAX pagination

        $('.hkt--pagination').on('click', 'a', function (e) {
            e.preventDefault();
            var url = $(this).attr('href');

            $.ajax({
                type: 'get',
                url: url,
                success: function (result) {
                    var html = $(result);
                    $(html.find('#ajax-content').html()).hide().appendTo('#ajax-content').fadeIn(500);
                    $('#ajax-pagination').html(html.find('#ajax-pagination').html());
                },
            });
        });

        // Calendar widget

        $('.calendar--action a').one('click', function () {

            $('.calendar--view-months').slick({
                dots: false,
                speed: 500,
                arrows: false,
                infinite: false,
                slidesToShow: 3,
                slidesToScroll: 3,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ],
            });

            $('span.show--calendar-month-name').text($('.calendar--view-month:first-child table').data('month'));

            $('a.calendar--view-prev').addClass('disabled');

            /*eslint-disable */
            $('.calendar--view-months').on('afterChange', function (event, slick, currentSlide) {
                $('.calendar--view-controls a').removeClass('disabled');
                if (currentSlide === 0) {
                    $('a.calendar--view-prev').addClass('disabled');
                }

                var month = $(this).find('.calendar--view-month:eq(' + currentSlide + ')').find('table').data('month');
                $('span.show--calendar-month-name').text(month);

                if ($(window).width() > 767) {
                    if ((currentSlide + 3) === $('.calendar--view-month').length) {
                        $('a.calendar--view-next').addClass('disabled');
                    }
                } else {
                    if ((currentSlide + 1) === $('.calendar--view-month').length) {
                        $('a.calendar--view-next').addClass('disabled');
                    }
                }

            });
            /*eslint-enable */

            $('.calendar--view-controls a').click(function (e) {
                e.preventDefault();

                if ($(this).hasClass('calendar--view-prev')) {
                    $('.calendar--view-months').slick('slickPrev');
                } else {
                    $('.calendar--view-months').slick('slickNext');
                }
            });

        });

        // 2023
        /**
         *  Single show page ticket events (Shows)
         */
// Declare the variables in the module scope
        let ticketsVisibleItems;
        let ticketsItemThreshold;
        let ticketsShowMoreText;
        let ticketsShowLessText;


        let ticketsLoopIndex = 0;
        let ticketListEl = $('#single-show-ticket-list');
        let ticketsHtml = '';
        if (accordions) {
            ticketsVisibleItems = (accordions && !isNaN(parseInt(accordions.tickets_images_to_show)))
                ? parseInt(accordions.tickets_images_to_show)
                : 3;
            ticketsItemThreshold = (accordions && !isNaN(parseInt(accordions.tickets_images_threshold)))
                ? parseInt(accordions.tickets_images_threshold)
                : 3;

            ticketsShowMoreText = (accordions && accordions.tickets_show_more) ? accordions.tickets_show_more : 'More tickets';
            ticketsShowLessText = (accordions && accordions.tickets_show_less) ? accordions.tickets_show_less : 'Less tickets';


            // If the overrides are set, use them over the general setting values
            if (accordions && accordions.overrides && accordions.overrides.accordion_override_tickets) {
                let itemsToShow = parseInt(accordions.overrides.items_to_show__tickets);
                ticketsVisibleItems = isNaN(itemsToShow) ? ticketsVisibleItems : itemsToShow;

                let itemThreshold = parseInt(accordions.overrides.item_threshold__tickets);
                ticketsItemThreshold = isNaN(itemThreshold) ? ticketsItemThreshold : itemThreshold;
            }
        }
        // .END overrides

        if (ticketListEl.length) {

            let postId = ticketListEl.data('id');

            $.getJSON('/wp-json/hkt/ticket-list/' + postId + '/', function (response) {

                $('#single-show-ticket-list + .robust-calendar--loader-loading-relative').hide();

                let tickets = response.tickets;
                let hasTicketsToHide = tickets.length > Number(ticketsVisibleItems + ticketsItemThreshold);

                if (tickets && tickets.length > 0) {

                    $.each(tickets, function (key, val) {

                        let emphasis_class = '';
                        let emphasis_text = '';
                        // Venue name mapped
                        let venueLabel = map_event_venue(val.venue.name);

                        if (val.event_availability === 'none' || val.event_is_manually_sold_out) {
                            emphasis_class = ' sold-out';
                            emphasis_text = localize['Sold-out'];
                        } else if (val.event_availability === 'limited' || val.event_availability === 'low') {
                            emphasis_class = ' filling-up';
                            emphasis_text = localize['Filling up'] + ' – ' + localize['Buy tickets'];
                        } else {
                            emphasis_class = '';
                            emphasis_text = localize['Buy tickets'];
                        }

                        let currentArticle = '<article class="show--list-item data-id="' + val.id + '" data-typeof="' + typeof val.event_is_manually_sold_out + '"><div class="container"><div class="show--list--item-content"><h2><a href="' + val.url + '" class="utm-out"><span style="text-transform: lowercase !important;">' + localizeWeekday(val.weekday) + '</span> ' + val.date + ' <i class="fa fa-clock" aria-hidden="true"></i> ' + val.time + '</a></h2><p>' + venueLabel + '</p></div><div class="show--list--item-tickets"><a href="' + val.url + '" class="btn utm-out ' + emphasis_class + '">' + emphasis_text + '</a></div></div></article>';

                        //let html = '';
                        // Check if it is a number and if the loop index is the desired breakpoint
                        if (Number.isInteger(Number(ticketsVisibleItems)) && ticketsLoopIndex === +ticketsVisibleItems && hasTicketsToHide) {
                            ticketsHtml += '<div class="hkt-accordion">';
                            ticketsHtml += '<div class="hkt-accordion--item mobile-only" data-items-total="' + tickets.length + '" data-items-visible="' + ticketsVisibleItems + '" data-items-threshold="' + ticketsItemThreshold + '">';
                            ticketsHtml += '<div class="hkt-accordion--item-inner">';
                        }

                        ticketsHtml += currentArticle;

                        ticketsLoopIndex++;
                    });

                    // Add accordion wrapper ending if ticketsToShow is in use
                    if (Number.isInteger(Number(ticketsVisibleItems)) && hasTicketsToHide) {
                        // Add accordion wrapper end tag
                        ticketsHtml += '</div>'; //hkt-accordion--item-inner
                        ticketsHtml += '<div class="container hkt-accordion--item-trigger-container"><button class="hkt-accordion--item-trigger link-button" data-open-text="' + ticketsShowMoreText + '" data-close-text="' + ticketsShowLessText + '"><span>' + ticketsShowMoreText + '</span><i class="fa fa-plus-circle" aria-hidden="true"></i></button></div>';
                        ticketsHtml += '</div>'; //hkt-accordion--item
                        ticketsHtml += '</div>'; //hkt-accordion
                    }

                    // Append HTML
                    ticketListEl.append(ticketsHtml);

                } else {
                    $('#single-show-ticket-list-container').hide();
                }

            }).always(function () {
                // re-initialize accordions, since the data was loaded afterwards
                initializeHKTAccordions('show-tickets');
            });

        } else {
            // If no ticket wrapper is available, initialize the accordions on doc ready
            $(document).ready();
            {
                initializeHKTAccordions('no-ticket-wrapper'); // initialize accordions
            }
        }

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
