/** import external dependencies */
//import 'jquery';
import $ from 'jquery';

window.$ = $;
window.jQuery = $;

import Masonry from 'masonry-layout';
import jQueryBridget from 'jquery-bridget';

// Register Masonry as a jQuery plugin
jQueryBridget('masonry', Masonry, $);
import 'bootstrap';
//import 'backbone/backbone.js';
import 'waypoints/lib/jquery.waypoints.min.js';
import 'jquery-zoom/jquery.zoom.js';
import 'magnific-popup/dist/jquery.magnific-popup.js';
import 'slick-carousel/slick/slick.js';
import 'stacktable.js//stacktable.js';
import 'imagesloaded/imagesloaded.js';
import 'jquery-lazyload/jquery.lazyload.js';
import 'jquery-lazy/jquery.lazy.js';

// Helper scripts
import './context-navigation.js';

/** import local dependencies */
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
    /** All pages */
    common,
    /** Home page */
    home,
    /** About Us page, note the change from about-us to aboutUs. */
    aboutUs,
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());
