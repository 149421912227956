/* eslint no-console: 0 */
/**
 * Context navigation related code for shows
 *
 * - Depends on jQuery
 * - Called in Main.js
 */
( function($) {
  // Main scope vars
  let prevScrollpos = $(window).scrollTop();
  const classToToggle = 'fixed';
  const thresholdTime = 10;
  let windowTimeout;
  let isFixed = false;
  //let onlyDownAfterElementFixPoint = true;

  /**
   * Doc ready
   */
  $(function() {

    // If we are on a single-show page
    if ($('body.single-show').length) {
      // Vars
      const $targetElementParent = $('#context--navigation');
      const $targetElement = $('#context--navigation nav#content');
      const $targetFixedAfterElement = $('header#top');
      let targetElementOriginalTop = $targetElement.offset().top || 0;
      const offset = $targetElement.height() * 2;

      /**
       * Scroll action on multiple events (load, resize and scroll).
       */
      $(window).on('load resize scroll', function() {
        // Only trigger the scroll function if on mobile screen widths
        if ($(window).width() < 768) {
          clearTimeout(windowTimeout);
          windowTimeout = setTimeout(function() {
            scrollCheckFunction($targetElement, targetElementOriginalTop, $targetFixedAfterElement, offset); //, $targetElementParent);
            $targetElementParent.css({'height': $targetElement.height()});
          }, thresholdTime);
        }
        else {
          // Reset if not mobile
          $targetElement.removeClass(classToToggle);
          $targetElement.css({'top': 0, 'transition': 'none'});
          isFixed = false;
        }
      });

    }
  });

  /**
   * Scroll function
   *
   * @param $targetElement
   * @param targetElementOriginalTop
   * @param $targetFixedAfterElement
   * @param offset
   * @param $targetElementParent
   */
  // function scrollCheckFunction($targetElement, targetElementOriginalTop, $targetFixedAfterElement, offset, $targetElementParent) {
  function scrollCheckFunction($targetElement, targetElementOriginalTop, $targetFixedAfterElement, offset) {

    // Vars
    let currentScrollPos = $(window).scrollTop();
    let targetElementHeight = $targetElement.height();
    let referenceElementHeight = $targetFixedAfterElement.height();
    //let viewportHeight = window.innerHeight;
    // Recalculate the original top position based on the viewport height
    //targetElementOriginalTop = $targetElementParent.offset().top - viewportHeight + referenceElementHeight + targetElementHeight;
    let elementFixPoint = ( targetElementOriginalTop + targetElementHeight + offset );

    //// console.log('%c cur %s >= targetOff %s  ', 'color:cyan', currentScrollPos, ( targetElementOriginalTop + offset ));
    //// console.log('%c isFixed :: %s ', 'color:cyan', isFixed);
    //// console.log('%c viewportHeight :: %s ', 'color:cyan', viewportHeight);
    //// console.log('%c $targetElementParent.offset().top  :: %s ', 'color:grey', $targetElementParent.offset().top);
    ////// console.log('%c onlyDownAfterElementFixPoint  :: %s ', 'color:red', onlyDownAfterElementFixPoint);

    if (currentScrollPos >= elementFixPoint && !isFixed) {
      //// console.log('%c Fix it  ', 'color:red');

      $targetElement.addClass(classToToggle);
      $targetElement.css({'top': -targetElementHeight});
      $targetElement.css({'transition': 'top 0.3s'});

      isFixed = true;

    }
    else if (currentScrollPos < targetElementOriginalTop && isFixed) {
      $targetElement.removeClass(classToToggle);
      $targetElement.css({'top': -targetElementHeight, 'transition': 'none'});
      isFixed = false;
      //onlyDownAfterElementFixPoint = true;
    }

    // Check for fixed
    if (isFixed) {
      // Check position
      //// console.log('%c PREV %s > Curr %s  ', 'color:orange', prevScrollpos, currentScrollPos);

      if (prevScrollpos > currentScrollPos) {
        //  if (!onlyDownAfterElementFixPoint) {
        //// console.log('%c SHOW', 'color:green');
        $targetElement.css({'top': referenceElementHeight});
        // }

      }
      else if (prevScrollpos + 5 < currentScrollPos) { // A small treshold
        //// console.log('%c HIDE', 'color:green');
        $targetElement.css({'top': -targetElementHeight});
        // onlyDownAfterElementFixPoint = false;
      }
    }

    // Update previous scroll position
    prevScrollpos = currentScrollPos > 0 ? currentScrollPos : prevScrollpos;
  }

} )(jQuery);
